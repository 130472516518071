<template>
  <b-overlay :show="loading" rounded="lg">
    <div>
      <!--modal for delivery completed-->
      <b-modal v-model="modalArrived" title="Delivery Order Completed">
        <b-container>
          <b-row>
            <b-col cols="12">
              <b><u>ALL&nbsp;</u></b> items in this DO accepted by the customer?
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button variant="success" class="ml-1" @click="updateComplete"
              >Accepted</b-button
            >
            <b-button variant="danger" class="ml-1" @click="modalArrived = false"
              >No</b-button
            >
          </div>
        </template>
      </b-modal>
      <!--modal for delivery completed-->

      <!--modal for delivery returned-->
      <b-modal v-model="modalReturn" title="Delivery Order Return">
        <b-container>
          <b-row>
            <b-col cols="12">
              Return item(s) to warehouse?
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button variant="success" class="ml-1" @click="updateReturn"
              >Yes</b-button
            >
            <b-button variant="danger" class="ml-1" @click="modalReturn = false"
              >No</b-button
            >
          </div>
        </template>
      </b-modal>
      <!--modal for delivery returned-->

      <!--modal for delivery collected-->
      <b-modal v-model="modalCollect" title="Self Collect">
        <b-container>
          <b-row>
            <b-col cols="12">
              <b><u>ALL&nbsp;</u></b> items in this DO accepted by the customer?
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button variant="success" class="ml-1" @click="showCustomer"
              >Accepted</b-button
            >
            <b-button variant="danger" class="ml-1" @click="modalCollect = false"
              >No</b-button
            >
          </div>
        </template>
      </b-modal>
      <!--modal for delivery collected-->

      <!--modal for customer information collected-->
      <b-modal v-model="modalCustomer" title="Customer Information ">
        <b-container>
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <span>Phone Number <span style="color:red">(*)</span></span>
                <b-form-input
                  v-model="customerCollect.idNumber"
                  type="text"
                  placeholder="Enter Phone Number"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <span>Customer Name <span style="color:red">(*)</span></span>
                <b-form-input
                  v-model="customerCollect.name"
                  type="text"
                  placeholder="Enter Customer Name"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button variant="success" class="ml-1" @click="updateSelfCollect"
              >Submit</b-button
            >
            <b-button variant="danger" class="ml-1" @click="modalCustomer = false"
              >Cancel</b-button
            >
          </div>
        </template>
      </b-modal>
      <!--modal for customer information collected-->

      <!--modal for approval return do-->
      <b-modal v-model="modalApprove" title="Approval Return DO">
        <b-container>
          <b-row>
            <b-col cols="12">
              <div class="d-flex">
                Are you sure want to Approve this Return DO?
              </div>
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button variant="success" class="ml-1" @click="updateApproved"
              >Approve</b-button
            >
            <b-button variant="danger" class="ml-1" @click="modalApprove = false"
              >Cancel</b-button
            >
          </div>
        </template>
      </b-modal>
      <!--modal for approval return do-->

      <!--modal for Acknowledgment return do-->
      <b-modal v-model="modalAcknowledgment" title="Acknowledgment Return DO">
        <b-container>
          <b-row>
            <b-col cols="12">
              <div class="d-flex">
                Are you sure want to Acknowledgment this Return DO?
              </div>
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button
              variant="success"
              class="ml-1"
              @click="updateAcknowledgment"
              >Acknowledge</b-button
            >
            <b-button
              variant="danger"
              class="ml-1"
              @click="modalAcknowledgment = false"
              >Cancel</b-button
            >
          </div>
        </template>
      </b-modal>
      <!--modal for Acknowledgment return do-->

      <!--modal for disapprove Acknowledgment return do-->
      <b-modal v-model="modalDisapproveApproval" title="Dissaporove Return Approval">
        <b-container>
          <b-row>
            <b-col cols="12">
              <div class="d-flex">
                Confirm to disapprove Return Approval?
              </div>
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button
              variant="warning"
              class="ml-1"
              @click="dissaporoveApproval"
              >Disapprove Return</b-button
            >
            <b-button
              variant="danger"
              class="ml-1"
              @click="modalDisapproveApproval = false"
              >Cancel disapprove return</b-button
            >
          </div>
        </template>
      </b-modal>
      <!--modal for Acknowledgment return do-->

      <!--modal for disapprove Acknowledgment return do-->
      <b-modal v-model="modalDisapproveAcknowledgment" title="Dissaporove Return Acknowledgment">
        <b-container>
          <b-row>
            <b-col cols="12">
              <div class="d-flex">
                Confirm to disapprove Return Acknowledgement? Please inform warehouse team manually that this return has been disapproved
              </div>
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button
              variant="warning"
              class="ml-1"
              @click="dissaporoveAcknowledgment"
              >Disapprove Return</b-button
            >
            <b-button
              variant="danger"
              class="ml-1"
              @click="modalDisapproveAcknowledgment = false"
              >Cancel disapprove return</b-button
            >
          </div>
        </template>
      </b-modal>
      <!--modal for Acknowledgment return do-->

      <!--modal for Redelivery do-->
      <b-modal v-model="modalRedelivery" title="Re-delivery DO">
        <b-container>
          <b-row>
            <b-col cols="12">
              <div class="d-flex">
                Are you sure want to Re-delivery this  DO?
              </div>
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button
              variant="success"
              class="ml-1"
              @click="updateRedelivery"
              >Accept</b-button
            >
            <b-button
              variant="danger"
              class="ml-1"
              @click="modalRedelivery = false"
              >Cancel</b-button
            >
          </div>
        </template>
      </b-modal>
      <!--modal for Redelivery do-->

      <!--modal for Accept Redelivery do-->
      <b-modal v-model="modalAcceptRedelivery" title="Accept Re-delivery DO">
        <b-container>
          <b-row>
            <b-col cols="12">
              <div class="d-flex">
                Are you sure want to Accept this Re-delivery DO?
              </div>
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button
              variant="success"
              class="ml-1"
              @click="acceptRedelivery"
              >Accept</b-button
            >
            <b-button
              variant="danger"
              class="ml-1"
              @click="modalAcceptRedelivery = false"
              >Cancel</b-button
            >
          </div>
        </template>
      </b-modal>
      <!--modal for Redelivery do-->

      <b-breadcrumb style="margin-bottom:20px">
        <b-breadcrumb-item to="/">
          <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
        </b-breadcrumb-item>
        <b-breadcrumb-item
          to="/delivery-orders/daikin"
          v-if="goBack === 'do-daikin'"
          >List Delivery Order Daikin</b-breadcrumb-item
        >
        <b-breadcrumb-item
          to="/delivery-orders/sumitomo"
          v-if="goBack === 'do-sumitomo'"
          >List Delivery Order Sumitomo</b-breadcrumb-item
        >
        <b-breadcrumb-item to="/self-collect/daikin" v-if="goBack === 'sc-daikin'"
          >List Self Collect Daikin</b-breadcrumb-item
        >
        <b-breadcrumb-item
          to="/self-collect/sumitomo"
          v-if="goBack === 'sc-sumitomo'"
          >List Self Collect Sumitomo</b-breadcrumb-item
        >
        <b-breadcrumb-item to="/shipping" v-if="goBack === 'shipping'"
          >List Shipping</b-breadcrumb-item
        >
        <b-breadcrumb-item
          to="/return-delivery/daikin"
          v-if="goBack === 'return-daikin'"
          >List Return Delivery Daikin</b-breadcrumb-item
        >
        <b-breadcrumb-item
          to="/return-delivery/sumitomo"
          v-if="goBack === 'return-sumitomo'"
          >List Return Delivery Sumitomo</b-breadcrumb-item
        >
        <b-breadcrumb-item
          to="/manual-return-delivery/daikin"
          v-if="goBack === 'manual-return-daikin'"
          >List Return Delivery Daikin</b-breadcrumb-item
        >
        <b-breadcrumb-item
          to="/manual-return-delivery/sumitomo"
          v-if="goBack === 'manual-return-sumitomo'"
          >List Return Delivery Sumitomo</b-breadcrumb-item
        >
        <b-breadcrumb-item to="/retrigger-api" v-if="goBack === 'resend'"
          >List Retrigger API</b-breadcrumb-item
        >
        <b-breadcrumb-item to="/redelivery/daikin" v-if="goBack === 'redelivery-daikin'"
          >List Redelivery Daikin</b-breadcrumb-item
          >
        <b-breadcrumb-item to="/redelivery/sumitomo" v-if="goBack === 'redelivery-sumitomo'"
          >List Redelivery Sumitomo</b-breadcrumb-item
        >
        <b-breadcrumb-item active>Detail Delivery Order - {{doData.status}}</b-breadcrumb-item>
      </b-breadcrumb>

      <b-card no-body>
        <!-- Header conditional-->
        <b-card-header v-if="pageone">
          <!-- 1. Common information this DO-->
          <h4 v-if="goBack === 'shipping'">Shipping</h4>
          <h4 v-if="goBack === 'resend'">Retrigger API</h4>
          <h4
            v-if="
              doData.shippingPoint === '1001' &&
                (goBack === 'do-daikin' ||
                  goBack === 'sc-daikin' ||
                  goBack === 'return-daikin' ||
                  goBack === 'manual-return-daikin' )
            "
          >
            Daikin Warehouse - {{doData.status}}
          </h4>
          <h4
            v-if="
              doData.shippingPoint === '1003' &&
                (goBack === 'do-sumitomo' ||
                  goBack === 'sc-sumitomo' ||
                  goBack === 'return-sumitomo' ||
                  goBack === 'manual-return-sumitomo')
            "
          >
            Sumitomo Warehouse - {{doData.status}}
          </h4>
        </b-card-header>

        <b-card-header v-if="pagetwo">
          <!-- 2. List Item information this DO-->
          <h4 v-if="goBack === 'shipping'">Shipping Item List</h4>
          <h4 v-if="goBack === 'resend'">Retrigger API Item List</h4>
          <h4
            v-if="
              doData.shippingPoint === '1001' &&
                (goBack === 'do-daikin' ||
                  goBack === 'sc-daikin' ||
                  goBack === 'return-daikin'||
                  goBack === 'manual-return-daikin' )
            "
          >
            Daikin Warehouse Item List - {{doData.status}}
          </h4>
          <h4
            v-if="
              doData.shippingPoint === '1003' &&
                (goBack === 'do-sumitomo' ||
                  goBack === 'sc-sumitomo' ||
                  goBack === 'return-sumitomo'||
                  goBack === 'manual-return-sumitomo')
            "
          >
            Sumitomo Warehouse Item List - {{doData.status}}
          </h4>
        </b-card-header>

        <b-card-header v-if="pagethree">
          <!-- 3. Detail List Item Scanned information this DO-->
          <h4 v-if="goBack === 'shipping'">Shipping Item Detail</h4>
          <h4 v-if="goBack === 'resend'">Retrigger API Item Detail</h4>
          <h4
            v-if="
              doData.shippingPoint === '1001' &&
                (goBack === 'do-daikin' ||
                  goBack === 'sc-daikin' ||
                  goBack === 'return-daikin'||
                  goBack === 'manual-return-daikin')
            "
          >
            Daikin Warehouse Item Detail - {{doData.status}}
          </h4>
          <h4
            v-if="
              doData.shippingPoint === '1003' &&
                (goBack === 'do-sumitomo' ||
                  goBack === 'sc-sumitomo' ||
                  goBack === 'return-sumitomo'||
                  goBack === 'manual-return-sumitomo')
            "
          >
            Sumitomo Warehouse Item Detail - {{doData.status}}
          </h4>
        </b-card-header>
        <!-- End of Header conditional-->
        <b-card-body>
          <b-col cols="12">
            <b-row>
              <b-col md="3" cols="12" class="mb-2">
                <b-button
                  size="md"
                  variant="primary"
                  @click="$router.go(-1)"
                  v-if="goBack !== 'other'"
                  ><feather-icon size="16" icon="HomeIcon" /> Main Menu</b-button
                >
                <b-button
                  size="md"
                  variant="primary"
                  @click="$router.go(-1)"
                  v-if="goBack === 'other'"
                  >Back</b-button
                >
              </b-col>
              
            </b-row>
            
            <!--
            <b-col cols="3" class="mb-2 hide-on-mobile">
              <b-button
                v-show="goBack == 'return-daikin' || goBack === 'return-sumitomo'"
                variant="secondary"
                @click="sendReturnToFTP()"
                ><feather-icon size="16" icon="PrinterIcon" /> Send Return to FTP</b-button
              >
            </b-col>
            -->
          </b-col>

          <!-- Separate one by one page on this document-->
          <b-col cols="12" class="mt-2" v-if="pageone">
            <!-- 1. Common information this DO-->
            <b-row>
              <b-col cols="12">
                <div class="d-flex flex-column">
                  <label>SAP DO Number</label>
                  <p class="font-weight-bold">{{ doData.sapDo }}</p>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="d-flex flex-column">
                  <label>SAP SO Number</label>
                  <p class="font-weight-bold">{{ doData.sapSo }}</p>
                </div>
              </b-col>

              <b-col cols="12" v-show="isReturn(doData.status)">
                <div class="d-flex flex-column">
                  <label>Return Number</label>
                  <p class="font-weight-bold">{{doData.returnNumber }}</p>
                </div>
              </b-col>
              <b-col cols="12" v-show="isReturn(doData.status)">
                <div class="d-flex flex-column">
                  <label>Return Reason</label>
                  <p class="font-weight-bold">{{doData.reason }}</p>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="d-flex flex-column">
                  <label>Delivery Date</label>
                  <p class="font-weight-bold">{{ dates(doData.deliveryDate) }}</p>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="d-flex flex-column">
                  <label>Delivery Time</label>
                  <p class="font-weight-bold">{{ showTime(doData.priority) }}</p>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="d-flex flex-column">
                  <label>PIN</label>
                  <p class="font-weight-bold">{{ doData.pin ? doData.pin : 'Not Set' }}</p>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="d-flex flex-column">
                  <label>Delivery Address</label>
                  <p class="font-weight-bold">
                    {{ doData.deliveryAddress1 }}
                  </p>
                  <p class="font-weight-bold">
                    {{ doData.deliveryAddress2 }}
                  </p>
                  <p class="font-weight-bold">
                    {{ doData.deliveryAddress3 }}
                  </p>
                  <p class="font-weight-bold">{{ doData.deliveryCity }}</p>
                  <p class="font-weight-bold">
                    {{ doData.deliveryPostalCode }}
                  </p>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="d-flex flex-column">
                  <label>Business Partner</label>
                  <p class="font-weight-bold">{{ doData.CustomerName1 +" "+doData.CustomerName2 }}</p>
                </div>
              </b-col>
              <b-col cols="12">
                <div class="d-flex flex-column">
                  <label>Customer Information</label>
                  <p class="font-weight-bold">{{ doData.CustomerName3 }}</p>
                  <p class="font-weight-bold">{{ doData.phone }}</p>
                </div>
              </b-col>
              <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Customer PO</label>
                <p class="font-weight-bold">
                  {{ doData.customerPo.includes('-') && doData.customerPo.startsWith('RSP') ? doData.customerPo.substring(12) : doData.customerPo }}
                </p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>RSP Order Number</label>
                <p class="font-weight-bold">
                  {{ doData.customerPo.includes('-') && doData.customerPo.startsWith('RSP') ? doData.customerPo.substring(0, 11) : '' }}
                </p>
              </div>
            </b-col>

              <b-col md="2" cols="12" class="mb-2">
              <b-button
                block
                  :disabled="loading || delay"
                  v-show="goBack.includes('shipping')||goBack.includes('sc')"
                  variant="secondary"
                  @click="printDO()"
                  ><feather-icon size="16" icon="PrinterIcon" /> Print DO</b-button
                >
              </b-col>
              <b-col md="3" cols="12" class="mb-2">
                <b-button
                block
                  :disabled="loading || delay"
                  v-show="goBack.includes('shipping')||goBack.includes('sc')"
                  variant="secondary"
                  @click="printWarrantyInstruction()"
                  ><feather-icon size="16" icon="PrinterIcon" /> Print Warranty Instruction</b-button
              >
              </b-col>
              <b-col cols="12">
    
              </b-col>
              <b-col md="2" cols="12" class="mb-2">
                <b-button
                  block
                  :disabled="loading"
                  size="md"
                  variant="primary"
                  @click="toPage()"
                  v-if="goBack !== 'other'"
                >
                  <feather-icon size="16" />
                  Back to Previous
                </b-button>
              </b-col>
              <b-col cols="12" md="3" class="mb-2">
                <b-button block size="md" @click="nextPage" variant="primary">Next</b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="mt-2" v-if="pagetwo">
            <!-- 2. List Item information this DO-->
            <b-row>
              <b-col cols="12">
                <div class="d-flex flex-column">
                  <label>SAP DO Number</label>
                  <p class="font-weight-bold">{{ doData.sapDo }}</p>
                </div>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <strong>Final Detail List Item </strong>
                </b-row >
                <b-row v-if="!checkReturn">
                  <b-table
                    :fields="scanFields"
                    :items="doData.DO_details"
                    :per-page="perPage"
                    :current-page="currentPage"
                    show-empty
                    responsive
                    :stacked="stackedStatus"
                  >
                    <template #cell(actions)="row">
                      <b-button
                        @click="selectItem(row)"
                        v-if="row.item.returnBarcode.length > 0"
                        variant="primary"
                        >Select</b-button
                      >
                    </template>

                    <template #cell(material)="row">
                      {{ getItem(row.item.material).itemModel }}
                    </template>

                    <template #cell(desc)="row">
                      {{ getItem(row.item.material).description }}
                    </template>

                    <template #cell(qty)="row">
                      <div v-if="row.item.qtyUpdate">
                        {{ row.item.qtyUpdate }}
                      </div>
                      <div v-else>
                        {{ row.item.qty }}
                      </div>
                    </template>
                    <template #cell(returned)="row">
                      {{ getSummary(row.item.material).returnedQty }}
                    </template>
                    <template #cell(scan)="row">
                      {{ getSummary(row.item.material).scanQty }}
                    </template>
                  </b-table>
                </b-row>
                <b-row v-else>
                  <b-table
                    :fields="scanFieldsFoCollected"
                    :items="doData.DO_details"
                    :per-page="perPage"
                    :current-page="currentPage"
                    show-empty
                    responsive
                    :stacked="stackedStatus"
                  >
                    <template #cell(actions)="row">
                      <b-button
                        @click="selectItem(row)"
                        v-if="row.item.returnBarcode.length > 0"
                        variant="primary"
                        >Select</b-button
                      >
                    </template>

                    <template #cell(material)="row">
                      {{ getItem(row.item.material).itemModel }}
                    </template>
                    <template #cell(qty)="row">
                      <div v-if="row.item.qtyUpdate !== undefined">
                        {{ row.item.qtyUpdate }}
                      </div>
                      <div v-else>
                        {{ row.item.qty }}
                      </div>
                    </template>
                    <template #cell(desc)="row">
                      {{ getItem(row.item.material).description }}
                    </template>
                    <template #cell(scan)="row">
                      {{ getSummary(row.item.material).scanQty }}
                    </template>
                  </b-table>
                </b-row>
                <b-row class="paging-center-mobile">
                  <b-pagination
                    :total-rows="doData.DO_details.length"
                    :per-page="perPage"
                    v-model="currentPage"
                  />
                </b-row>
              </b-col>
              <b-col>
                <b-row class="mt-2">
                  <strong>Detail List Return Item </strong>
                </b-row >
                <div v-if="!checkReturn">
                  <b-col cols="3">
                    <v-select
                      class="mb-2 mt-2"
                      :options="selectOptions"
                      v-model="currentFlag"
                      @input="selectFlag"
                      label="text"
                    ></v-select>
                  </b-col>
                  
                  <b-row v-if="!checkReturn">
                    <b-table
                      v-for="(items, flag) in filteredItems"
                      v-if="currentFlag == flagLabel(flag)"
                      :key="flag"
                      :fields="ReturnScanFields"
                      :items="items"
                      :per-page="perPage"
                      :current-page="currentPageReturn"
                      show-empty
                      responsive
                      :stacked="stackedStatus"
                    >
                      <template #cell(actions)="row">
                        <b-button
                          @click="selectItem(row)"
                          v-if="row.item.returnBarcode.length > 0"
                          variant="primary"
                        >Select</b-button>
                      </template>
                      <template #cell(material)="row">
                        {{ getItem(row.item.material).itemModel }}
                      </template>
                      <template #cell(desc)="row">
                        {{ getItem(row.item.material).description }}
                      </template>
                      <template #cell(qty)="row">
                        <div v-if="row.item.qtyUpdate">
                          {{ row.item.qtyUpdate }}
                        </div>
                        <div v-else>
                          {{ row.item.qty }}
                        </div>
                      </template>
                      <template #cell(scan)="row">
                        {{ getSummary(row.item.material).scanQty }}
                      </template>
                      <template #cell(barcode)="row">
                        {{ row.item.qty }}
                      </template>
                    </b-table>
                  </b-row>
                  <b-row class="paging-center-mobile">
                    <b-pagination
                      :total-rows="getTotalRows()"
                      :per-page="perPage"
                      v-model="currentPageReturn"
                    />
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3" class="adjust-on-mobile">
                <b-button
                  @click="backPage"
                  class="w-100 d-flex justify-content-center"
                  variant="primary"
                  >Back</b-button>
              </b-col>
              <!--this for return delivery needed-->
              <b-col md="3" class="adjust-on-mobile" v-if="checkRedelivery">
                <b-button
                  @click="modalRedelivery = true"
                  class="w-100 d-flex justify-content-center"
                  variant="danger"
                  >Re-delivery</b-button
                >
              </b-col>
              <!--this for return delivery needed-->
              <b-col md="3" class="adjust-on-mobile" v-if="checkReturn">
                <b-button
                  @click="modalReturn = true"
                  class="w-100 d-flex justify-content-center"
                  variant="danger"
                  >Delivery Returned</b-button
                >
              </b-col>
              <!--this for warehouse role. temporary check when open this page from shipping table list-->
              <b-col
                md="3"
                class="adjust-on-mobile"
                v-if="
                  permission.arrived &&
                    goBack === 'shipping' &&
                    doData.status === 'Delivery in Progress'
                "
              >
                <b-button
                  @click="modalArrived = true"
                  class="w-100 d-flex justify-content-center"
                  variant="success"
                  >Accept Delivery</b-button
                >
              </b-col>
              <!--this for collected role. temporary check when open this page from self collect table list-->
              <b-col md="3" class="adjust-on-mobile" v-if="checkCollected">
                <b-button
                  @click="modalCollect = true"
                  class="w-100 d-flex justify-content-center"
                  variant="success"
                  >Completed</b-button
                >
              </b-col>
              <!--this is for return team role-->
              <b-col md="3" class="adjust-on-mobile" v-if="checkApproval">
                <b-button
                  @click="modalApprove = true"
                  class="w-100 d-flex justify-content-center"
                  variant="success"
                  >Approve</b-button
                >
              </b-col>
              <b-col md="3" class="adjust-on-mobile" v-if="checkAcknowledge">
                <b-button
                  @click="modalAcknowledgment = true"
                  class="w-100 d-flex justify-content-center"
                  variant="success"
                  >Acknowledge</b-button
                >
              </b-col>
              <b-col md="3" class="adjust-on-mobile" v-if="checkAcknowledge">
                <b-button
                  @click="modalDisapproveAcknowledgment = true"
                  class="w-100 d-flex justify-content-center"
                  variant="danger"
                  >Disapprove Acknowledgment</b-button
                >
              </b-col>
              <b-col md="3" class="adjust-on-mobile" v-if="checkApproval">
                <b-button
                  @click="modalDisapproveApproval = true"
                  class="w-100 d-flex justify-content-center"
                  variant="danger"
                  >Disapprove Approval</b-button
                >
              </b-col>
              <b-col md="3" class="adjust-on-mobile" v-if="isRedelivery(doData.status)">
                <b-button
                  @click="modalAcceptRedelivery = true"
                  class="w-100 d-flex justify-content-center"
                  variant="success"
                  >Accept Redelivery</b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="mt-2" v-if="pagethree">
            <!-- 3. Detail List Item Scanned information this DO-->
            <b-row>
              <b-col cols="12">
                <div class="d-flex flex-column">
                  <label>SAP DO Number</label>
                  <p class="font-weight-bold">{{ doData.sapDo }}</p>
                </div>
              </b-col>
              <b-col cols="12">
                <div class="d-flex flex-column">
                  <label>Model No</label>
                  <p class="font-weight-bold">
                    {{ getItem(selectScan.material).itemModel }}
                  </p>
                </div>
              </b-col>
              <b-col cols="12">
                <div class="d-flex flex-column">
                  <label>Description</label>
                  <p class="font-weight-bold">
                    {{ getItem(selectScan.material).description }}
                  </p>
                </div>
              </b-col>
              <b-col cols="12">
                <div class="d-flex flex-column">
                  <label>DO QTY</label>
                  <p
                    class="font-weight-bold"
                    v-if="selectScan.qtyUpdate !== undefined"
                  >
                    {{ selectScan.qtyUpdate }}
                  </p>
                  <p class="font-weight-bold">{{ selectScan.qty }}</p>
                </div>
              </b-col>
              <b-col cols="12" v-if="selectScan.qtyUpdate == undefined">
                <div class="d-flex flex-column">
                  <label>Return DO QTY</label>
                  <p
                    class="font-weight-bold"
                  >
                    {{ selectScan.returnBarcode.length }}
                  </p>
                </div>
              </b-col>

              <b-col cols="12">
                <b-row>
                  <strong>Final Detail List Item : </strong>
                </b-row>
                <b-row>
                  <b-table :fields="itemFields" :items="listItemScan" show-empty>
                    <template #cell(barcode)="row">
                      <!-- {{ row.item.scannedBarcode }} -->
                      {{ row.item.returnBarcode }}
                    </template>
                    <template #cell(flag)="row">
                      <!-- {{ row.item.scannedBarcode }} -->
                      {{ row.item.flag }}
                    </template>
                  </b-table>
                </b-row>
              </b-col>
              <b-col cols="8">
                <b-button @click="backPageTwo" variant="primary">Back</b-button>
              </b-col>
              <b-col cols="4"> </b-col>
            </b-row>
          </b-col>
          <!-- End of Separate one by one page on this document-->
        </b-card-body>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { userAccess, dateFormat } from "@/utils/utils";
import {
  downloadByPDFMake,
  convertPDFtoBase64,
} from "@/utils/utils.js";
import { logo, footer,header } from "./daikin-img-base64.js";
import QRCode from "qrcode";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";
import { sleep, pushTo }from '@/utils/utils.js'
import vSelect from "vue-select";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    "v-select": vSelect,
  },
  props: ["id", "from"],
  data() {
    return {
      // Logo and header
      daikinQR: require('@/assets/images/drcc/qr-code-ewarranty.png'),
      UATdaikinQR: require('@/assets/images/drcc/qr-uat-ewarranty.png'),
      daikinHeader: require('@/assets/images/drcc/Daikin_Header.png'),
      stackedStatus: "md",
      perPage: 10,
      currentPage: 1,
      currentPageReturn : 1,
      currentFlag: undefined, // Flag yang aktif saat ini
      //variabel for save detail DO
      doData: {},

      //for disable print button
      delay: true,

      //variabel for save movement data item at scan function
      scanItemTables: [],
      scanFields: [
        { key: "material", label: "Item Model" },
        { key: "qty", label: "DO Original Qty" },
        { key: "returned", label: "Returned" },
        { key: "scan", label: "To Return" },
        { key: "actions", label: "Action" },
      ],
      ReturnScanFields: [
        { key: "material", label: "Item Model" },
        { key: "item", label: "Barcode" },
      ],
      scanFieldsFoCollected: [
        { key: "material", label: "Item Model" },
        { key: "desc", label: "Description" },
        { key: "qty", label: "DO Qty" },
        { key: "scan", label: "Scan" },
        { key: "actions", label: "Action" },
      ],
      returnFields: [
        { key: "material", label: "Item Model" },
        { key: "return", label: "Return" },
        { key: "actions", label: "Action" },
      ],
      itemFields: [{ key: "barcode", label: "Barcode"},
      {key:"flag",label:"Return Number" }],

      modalArrived: false,
      modalReturn: false,
      modalCustomer: false,
      modalCollect: false,
      modalApprove: false,
      modalAcknowledgment: false,
      modalDisapproveAcknowledgment : false,
      modalDisapproveApproval : false,
      modalRedelivery: false,
      modalAcceptRedelivery:false,

      //initiate active page 1 to 3
      pageone: true,
      pagetwo: false,
      pagethree: false,

      postal: [],
      itemCatalog: [],

      selectData: "", //this one variable for save item info after scan function
      selectScan: "", //this one variable for save item info after select item to prepare scanning
      selectIndex: 0, //same as selectData and selectScan, but makes more sense
      goBack: "", //this one variable to mark this page is opened from what table list?
      goBackStatus:"",
      backPath:"",
      loading : false,
      customerCollect: {
        idNumber: "",
        name: "",
      },
    };
  },
  mounted() {
    this.goBack = this.$route.params.from;
    this.goBackStatus = this.$route.params.status;

    if(this.goBack==='do-daikin'){
        this.backPath= '/delivery-orders/daikin';
    }else if(this.goBack==='do-sumitomo'){
      this.backPath= '/delivery-orders/sumitomo';
    }else if(this.goBack==='sc-daikin'){
      this.backPath=`/self-collect/daikin`;
    }else if(this.goBack==='sc-sumitomo'){
      this.backPath=`/self-collect/sumitomo`;
    }else if(this.goBack==='shipping-daikin'){
      this.backPath=`/shipping/daikin`;
    }else if(this.goBack==='shipping-sumitomo'){
      this.backPath=`/shipping/sumitomo`;
    }else if(this.goBack === 'return-sumitomo'){
      this.backPath='/return-delivery/sumitomo';
    }else if(this.goBack === 'return-daikin'){
      this.backPath='/return-delivery/daikin';
    }else if(this.goBack === 'redelivery-sumitomo'){
      this.backPath='/redelivery/sumitomo';
    }else if(this.goBack === 'redelivery-daikin'){
      this.backPath='/redelivery/daikin';
    }else if(this.goBack === 'resend'){
      this.backPath='/retrigger-api';
    }else if (this.goBack === "manual-return-daikin") {
      this.backPath='/manual-return-delivery/daikin';
    } else if (this.goBack === "manual-return-sumitomo") {
      this.backPath='/manual-return-delivery/sumitomo';
    } else{
      this.$bvToast.toast('Shipping Point Not Found', {
        title: "Warning",
        variant: "danger",
        solid: true,
        });
      }
    
    //temporary get api items catalog from axios
    /*
    axios
      .get("sales-and-purchase/drcc/items?total=100")
      .then((response) => {
        this.itemCatalog = response.data.data;
        //console.log("itemCatalog", this.itemCatalog);
      })
      .catch((e) => {
        console.log(e);
      });
      */
      //console.log('permission ',this.permission)
    //temporary get api post code from axios
    // axios.get('sales-and-purchase/drcc/postal').then((response) => {
    //     this.postal = response.data.data;
    // }).catch((e) => {
    //     console.log(e)
    // })
  },
  created() {
    this.$store
      .dispatch("delivery/detailDelivery", {
        id: this.id,
      })
      .then((Detail) => {
        this.dataPopulate(Detail);
      })
      .catch((err) => {
        console.log({ err });
      });
  },
  methods: {
    ...mapActions({
      testScanDO: "delivery/updateDO",
    }),
    selectFlag(flag) {
      this.currentFlag = flag.text;
    },
    flagLabel(flag) {
      if (flag == 'undefined') {
        return 'Normal Return';
      } else {
        return `Manual Return ${flag}`;
      }
    },
    getMaxFlag() {
      return Object.keys(this.filteredItems).length - 1;
    },
    getTotalRows() {
      return this.filteredItems[this.currentFlag]?.length || 0;
    },
    async printLayout() {
      //console.log("this do data ", this.doData);

      /**
        Get Dealer Data
      **/

      var dealer = await this.$store.dispatch("dealer/getDealerWithQuery", {
        dealerName: this.doData.dealerNo,
      });

      var dealerName = dealer.data.length > 0 ? dealer.data[0].dealerName : "";

      //Looping for DO Detail
      //Mapping DO Detail
      var itemList = this.doData.DO_details.map((x) => {
        var itemModelCheck = this.getItem(x.material).itemModel
        if (!itemModelCheck || itemModelCheck== ' ' || itemModelCheck == undefined || itemModelCheck== '') return
        return {
          model: itemModelCheck,
          qty: (x.qtyUpdate!=undefined) ? x.qtyUpdate : x.qty,
          empty: "",
          barcode: (x.skip)?"Skip Scan":x.scannedBarcode
            .map((x) => {
              return x.itemUsed;
            })
            .join("   "),
        };
      }).filter(item => item && item.qty !== 0 && (!item.qtyUpdate || item.qtyUpdate !== 0)); // Tambahkan filter untuk memeriksa qty

      //listAllScanned function is not required

      //itemList.barcode = this.listAllScanned(itemList.model);
      //console.log("barcodes ", itemList.barcode);
      var baseURL = config.RSP_URL || "https://rsp.daikin.com.sg"

      // Generate the QR code as a base64-encoded image
      const qrCode = await QRCode.toDataURL(
        baseURL+"/pages/login-do/" + this.doData.sapDo
      );
      /*
      const toDataURL = (url) =>
        fetch(url)
          .then((response) => response.blob())
          .then(
            (blob) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
          );
      let header64;
      await toDataURL(this.daikinHeader).then((res) => {
        header64 = res;
      });
      */

      if (pdfMake.vfs == undefined) {
        // var pdfFonts = require("./customVfs.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }

      const tableData = {
        type: "table",
        data: {
          widths: ["25%", "10%", "52%", "13%"],
          header: ["Model", "Quantity", "Barcode", "Return QTY"],
          dataTable: itemList,
          valueTable: ["model", "qty", "barcode", "empty"],
          noBorder: false,
        },
      };
      const qrTableData ={
        type: "table",
        data:{
          widths:["15%","35%","15%","35%"],
          header:["Return","Quantity:","Redelivery","Date:\t\t\t\t\t\tPostal:\t\t\t\t\t\t\t"],
          dataTable:[],
          noBorder:false
        }
      }

      const customerData = [
        {
          text: "Delivery Order",
          value: this.doData.sapDo,
        },
        {
          text: "Delivery Date",
          value: this.dates(this.doData.deliveryDate),
          //},{
          //  text:'Delivery Time',
          //  value:this.showTime(this.doData.priority)
        },
        {
          text: "Sales Order",
          value: this.doData.sapSo,
          boldValue: true,
        },
        {
          text: "Purchase Order",
          value: this.doData.customerPo.includes('-') && this.doData.customerPo.startsWith('RSP') ? this.doData.customerPo.substring(12) : this.doData.customerPo,
        },
        {
          text: "RSP Order Number",
          value: this.doData.customerPo.includes('-') && this.doData.customerPo.startsWith('RSP') ? this.doData.customerPo.substring(0, 11) : "",
        },
        {
          text: "Business Partner",
          value:
            this.doData.CustomerName1 + "" + this.doData.CustomerName2 + "",
        },
        {
          text: "Dealer Code",
          value: this.doData.dealerNo,
        },
        {
          text: "Customer Name",
          value: this.doData.CustomerName3,
        },
        {
          text: "Phone",
          value: this.doData.phone,
        },
        {
          text: "Address",
          value: this.doData.deliveryAddress1,
        },
        {
          text: null,
          value: this.doData.deliveryAddress2,
        },
        {
          text: null,
          value: this.doData.deliveryAddress3,
        },
        {
          text: null,
          value:
            this.doData.deliveryCity + " " + this.doData.deliveryPostalCode,
        },
      ];

      //console.info('header ',header64);
      //console.info('footer ',footer)

      let docDefinition = {
        pageMargins: [70, 120, 70, 70],
        header: function(currentPage, pageCount, pageSize) {
          return [
            {
              image: `${header}`,
              alignment: "center",
              width: 520,
              margin: [0, 50, 0, 0],
            },
          ];
        },
        footer: function(currentPage, pageCount) {
          return [
            {
              image: `${footer}`,
              alignment: "center",
              width: 600,
              margin: [0, 0, 0, 50],
            },
          ];
        },

        content: [
          {
            layout: "noBorders",
            table: {
              widths: ["*", "auto"],
              body: [
                [
                  {
                    // alignment: "center",
                    // width: "auto",
                    image: qrCode,
                    fit: [100, 100],
                  },
                  {
                    text: `\n\n\nScan this QR Code to accept/return the delivery:\n\n`+
                    `- To accept all items, choose "Accept Delivery"\n`+
                    `- To return one or more items, choose "Delivery Returned"*\n`+
                    `- To reschedule delivery of all items, choose "Re-delivery"*\n\n`+
                    `* Additional transport charges will be imposed.\n\n`,
                    //`Return\t\tQuantity:\n`+
                    //`Redelivery\tDate:\t\t\tPostal:\n`,
                    fontSize: 10,
                    margin: [0, -22, 0, 0],
                  },
                ],
              ],
              border: [],
            },
            margin: [0, 10, 0, 10],
          },
          /*
          { text: `\n` },
          {
            table: {
              widths: qrTableData.data.widths,
              headerRows: 1,
              body: [
                qrTableData.data.header.map((header) => ({
                  text: header,
                  fontSize: 10,
                })), // Table header
                ...qrTableData.data.dataTable.map((item) =>
                  qrTableData.data.valueTable.map((key) => ({
                    text: item[key] + "   ",
                    fontSize: 10,
                  }))
                ), // Dynamic rows
              ],
              
            },
          },
          */

          // { text: `\n` },

          { text: `Delivery Detail`, bold: true },

          { text: `\n` },

          {
            layout: "noBorders",
            table: {
              widths: ["20%", "1% ", "79%"],
              headerRows: 1,
              body: [
                ...customerData.map((customer) => {
                  if (customer.text == null) {
                    return [
                      { text: "", fontSize: 10 },
                      { text: "", fontSize: 10 },
                      { text: customer.value, fontSize: 10 },
                    ];
                  }
                  return [
                    { text: customer.text, fontSize: 10 },
                    { text: ":", fontSize: 10 },
                    {
                      text: customer.value,
                      fontSize: 10,
                      bold: customer.boldValue,
                    },
                  ];
                }),
              ],
              border: [],
            },
            margin: [0, 10, 0, 10],
          },

          /*

          {text:`Delivery Order     : ${this.doData.sapDo}`,fontSize:10,},
          {text:`Delivery Date      : ${this.dates(this.doData.deliveryDate)}`,fontSize:10,},
          {text:`Delivery Time      : ${this.showTime(this.doData.priority)}`,fontSize:10,},
          {text:`Sales Order         : ${this.doData.sapSo}`,fontSize:10,},
          {text:`Purchase Order  : ${this.doData.customerPo}`,fontSize:10,},
          {text:`Dealer Code        : ${this.doData.dealerNo}`,fontSize:10,},
          {text:`Name                  : ${this.doData.CustomerName1} ${this.doData.CustomerName2} ${this.doData.CustomerName3} ${this.doData.CustomerName4}`,fontSize:10,},
          {text:`Address              : ${this.doData.deliveryAddress1}, ${this.doData.deliveryPostalCode}`,fontSize:10,},
          {text:`Phone                 : ${this.doData.phone}`,fontSize:10,},
          **/
          { text: `\n` },
          // { text: `\n` },

          { text: `Item Detail`, bold: true },

          { text: `\n` },

          {
            table: {
              widths: tableData.data.widths,
              headerRows: 1,
              body: [
                tableData.data.header.map((header) => ({
                  text: header,
                  fontSize: 10,
                })), // Table header
                ...tableData.data.dataTable.map((item) =>
                  tableData.data.valueTable.map((key) => ({
                    text: item[key] + "   ",
                    fontSize: 10,
                  }))
                ), // Dynamic rows
              ],
              ...(tableData.data.noBorder && { style: "noBorders" }), // Optional: Remove table borders
            },
          },
          // {
          //   type: "table",
          //   data: {
          //     widths: ["25%", "15%", "60%"],
          //     header: ["Model", "Quantity", "Barcode"],
          //     dataTable: itemList,
          //     valueTable: [`model`, `qty`, `barcode`],
          //     noBorder: false,
          //   },
          // },

          /**
          {
            type:'text',
            data:`${this.user.props.firstName} ${this.user.props.lastName}, `
          },
          */
        ],
      };
      return docDefinition;
    },

    async printDO() {
      this.loading = true
      var docDefinition = await this.printLayout().catch((err) => {
        this.isLoading=false
        this.$bvToast.toast(
            "Load data failed, please reload the page",
            {
              title: "Failed",
              variant: "danger",
              solid: true,
            }
          );
        return 
      })

      let fileName = `DO-${this.doData.sapDo}`;
      if (docDefinition) {
        pdfMake.createPdf(docDefinition).download(fileName);
        this.loading = false
      }
    },
    // untuk print warranty
    async printWarrantyInstruction() {
      var baseURL = config.EWARRANTY_URL || "https://ewarranty.daikin.com.sg"

      this.loading = true
      const toDataURL = (url) =>
        fetch(url)
          .then((response) => response.blob())
          .then(
            (blob) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
          );
      let qrcode = (config.IS_UAT==undefined)?this.daikinQR:this.UATdaikinQR;
      let qrcode64;
      await toDataURL(qrcode).then((res) => {
        qrcode64 = res;
      });
      let getData = {};
      let inventoryData = await this.$store.dispatch("inventory/getAllInventories", {
        sapDo: this.doData.sapDo,
        entry: "10000"
      })
      let inventoryArray = inventoryData.data
      let pdfContent = []
      let docDefinition = {content : []}
      for (let i = 0; i < inventoryArray.length; i++) {
        let model = inventoryArray[i].itemModel;
        let serial = inventoryArray[i].serial;
        let barcode = inventoryArray[i].barcode;
        let dealerCode = inventoryArray[i].dealerCode;
        let DONumber = inventoryArray[i].sapDo;
        let distChan = inventoryArray[i].industryType ? inventoryArray[i].industryType : '';
        let listChannel = ['DL', 'DR', 'DS', 'M1', 'M2', 'M3'];
        let type = listChannel.includes(distChan) ? 'Route' : 'Project';
        let skip = inventoryArray[i].skip

        // ... buat objek docDefinition sesuai dengan data saat ini ...
        // var pdfMake = require("pdfmake/build/pdfmake.js");
        if (pdfMake.vfs == undefined) {
          // var pdfFonts = require("./customVfs.js");
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        let date = new Date().toISOString().substring(0, 10);
        function dateTransform(date) {
          return new Date(date).toUTCString().substring(5, 16);
        }
        if(skip !== true){
          if(i != inventoryArray.length - 1 ){
            pdfContent.push( 
            { text: `WARRANTY REGISTRATION CARD \n`, color:'#0ABDFF' },
            { text: `valid in Singapore only \n`, color:'#0ABDFF', fontSize:10 },  
            { text: `\n` },
            {columns:[
              { text: `Register online at ${baseURL} \nGo Paperless, Build a Greener Future with Daikin\n` },
              {image: `${qrcode64}`,
              width:85,
              height:85,
              absolutePosition: { x: 580, y: 145 }
                // margin:[120,0,0,0],
                // fit:[75,75]
                }
            ]},
               
            { text: `\n` },
            { text: `\n` },
            {columns:[
              { text: `Model No: ${model}\n\nSerial No: ${serial}\n\nBarcode No: ${barcode}` },
              { text: `Dealer: ${dealerCode}\n\nWarranty Type: ${type}\n\nDO No: ${DONumber}` }
            ]},
             
            { text: `\n` },
            { text: `\n` },
             
            { text: `Important: \n`, bold:true },
            { text: `Please register within 14 days from installation date. \n` },
            { text: `A digital warranty certificate will be generated upon successful registration. \n` },
            { text: `You can view your certificate through ${baseURL}/viewwarranty\n` },
            { text: `For any warranty enquiry, please call us at 65838888 ext 2196\n` },
             
            { text: `\n` },
             
            {
              text: '                   Thank you for choosing Daikin, the World’s Leading Air conditioningManufacturer                ',
              color:'#ffffff',
              background:'#0AAEFF',
              alignment:'center',
            },
            { text: '', pageBreak: 'after' } // Menambahkan page break di akhir konten ini
            )
          }
         
          if ( i == inventoryArray.length - 1){
            pdfContent.push( 
            { text: `WARRANTY REGISTRATION CARD \n`, color:'#0ABDFF' },
            { text: `valid in Singapore only \n`, color:'#0ABDFF', fontSize:10 },  
            { text: `\n` },
            {columns:[
              { text: `Register online at ${baseURL} \nGo Paperless, Build a Greener Future with Daikin\n` },
              {image: `${qrcode64}`,
              width:85,
              height:85,
              absolutePosition: { x: 580, y: 145 }
                // margin:[120,0,0,0],
                // fit:[75,75]
                }
            ]},
               
            { text: `\n` },
            { text: `\n` },
            {columns:[
              { text: `Model No: ${model}\n\nSerial No: ${serial}\n\nBarcode No: ${barcode}` },
              { text: `Dealer: ${dealerCode}\n\nWarranty Type: ${type}\n\nDO No: ${DONumber}` }
            ]},
             
            { text: `\n` },
            { text: `\n` },
             
            { text: `Important: \n`, bold:true },
            { text: `Please register within 14 days from installation date. \n` },
            { text: `A digital warranty certificate will be generated upon successful registration. \n` },
            { text: `You can view your certificate through ${baseURL}/viewwarranty\n` },
            { text: `For any warranty enquiry, please call us at 65838888 ext 2196\n` },
             
            { text: `\n` },
             
            {
              text: '                   Thank you for choosing Daikin, the World’s Leading Air conditioningManufacturer                ',
              color:'#ffffff',
              background:'#0AAEFF',
              alignment:'center',
            },
          )
          }
        }
        // Tambahkan konten item ke dokumen dengan pageBreak
        docDefinition.content.push(pdfContent);
      }
      // optimal width header 500
      docDefinition = {
        pageOrientation: 'landscape', // Mengatur orientasi halaman ke landscape
        pageMargins: [125, 150, 125, 50],
        header: function(currentPage, pageCount, pageSize) {
          return [
            {
              image: `${header}`,
              alignment: "center",
              width: 700,
              margin: [0, 50, 0, 0],
            },
          ];
        },
        content: pdfContent,
        defaultStyle: {
        fontSize: 13,
        }
      };
      // Buat PDF untuk item saat ini
      pdfMake
      .createPdf(docDefinition)
      .download(`Daikin Warranty Registration Card.pdf`);
      this.loading = false

    },

    sendReturnToFTP(){

      this.$store
      .dispatch("delivery/sendReturnToFTP", this.doData._id)
      .then((Detail) => {
        this.$bvToast.toast('DO Return :'+this.doData.sapDo+' sent to FTP', {
          title: "Success",
          variant: "success",
          solid: true,
          });
      })
      .catch((err) => {
        console.log({ err });
      });

    },
    toPage(){
      this.$router.push({
        path:this.backPath,
        query: { status: this.goBackStatus }
      });
      
    },
    getItems(){
      var idList=this.doData.DO_details.map(x=>{return x.material});
    //temporary get api items catalog from axios
      var list= idList.join("|")
      console.info('getItems list ',list)
    axios
      .get("sales-and-purchase/drcc/items/multi?idList="+list)
      .then((response) => {
        this.itemCatalog = response.data.data;
        this.delay = false
        // console.log(this.itemCatalog)
      })
      .catch((e) => {
        this.$bvToast.toast("Load data failed, please reload the page.", {
          title: "Warning",
          variant: "danger",
          solid: true,
          });
        console.log(e);
      });
    },
    isReturn(status){
      let wantedStatus = [
        "Returned",
        "Waiting Acknowledgment",
        "Waiting Approval",
        "Approved",
        "Return Completed",
      ];
      var isReturn = wantedStatus.includes(status)
      //console.info('is return ',isReturn)
      return isReturn
    },
    isRedelivery(status){
      let wantedStatus = [
        "Re-Delivery"
      ];
      var isRedelivery = wantedStatus.includes(status)
      //console.info('is redelivery ',isRedelivery)
      //console.info('this.permission ',this.permission)
      
      if(!this.permission||!this.permission.accept){
        //if user don't have accept permission, return false
        return false
      }
      
      
      return isRedelivery
    },
    dataPopulate(Detail) {
      // console.log(Detail)
      let tableIndex = 0;
      this.scanItemTables = Detail.DO_details.reduce((prev, next) => {
        let expandedQty = [];
        var barcodeDO = next.scannedBarcode.map((x) => {
          return x.itemUsed;
        });
        var unscanBarcodeDO = next.returnBarcode.map((x) => {
          return {item:x.item,flag:x.flag};
        });
        for (let i = 0; i < next.qty; i++) {
          var tempBarcode, tempUnScan,tempUnscanFlag;

          //for original scan Barcode
          if (barcodeDO[i] === undefined) {
            tempBarcode = "";
          } else {
            tempBarcode = barcodeDO[i];
          }

          //for list return barcode
          if (unscanBarcodeDO[i] === undefined) {
            tempUnScan = "";
            tempUnscanFlag=""
          } else {
            tempUnScan = unscanBarcodeDO[i].item;
            tempUnscanFlag= (unscanBarcodeDO[i].flag==undefined)?"":unscanBarcodeDO[i].flag;
          }
          expandedQty.push({
            ...next,
            scannedBarcode: tempBarcode,
            returnBarcode: tempUnScan,
            flag:tempUnscanFlag,
            qty: 1,
            index: tableIndex,
            skip: false,
          });
          tableIndex++;
        }

        return [...prev, ...expandedQty];
      }, []);

      this.doData = Detail;
      this.getItems();
    },
    dates(date) {
      return dateFormat(date);
    },
    showTime(priority) {
      var time;
      if (priority === "11") {
        time = "Morning: 9AM - 12PM";
      } else if (priority === "12") {
        time = "Afternoon: 1PM - 3PM";
      } else if (priority === "13") {
        time = "Afternoon: 3PM - 6PM";
      } else if (priority === "03"){
        time = "Self Collect AMK"
      }else if(priority === "05"){
        time = "Self Collect Sumitomo"
      }
      else {
        time = "";
      }
      return time;
    },

    updateComplete() {
      //update status to fullfilled : This is for the shipping person if want to finish completed delivery
      //var data = { ...this.doData };
      //data.status = "Fulfilled";
      this.loading =true
      this.testScanDO({ idDelivery: this.doData._id, deliveryData: {status:"Fulfilled"} })
        .then(() => {
          //update item warranty status at inventory
          this.$bvToast.toast("The items has been delivered successfully", {
                title: "Success",
                variant: "success",
                solid: true,
              });
          this.loading = false;
          //this.$store.dispatch("delivery/getDeliveryOrder");
          this.goBackCall('Fulfilled')
        })
        .catch((e) => {
          // console.log(e)
          this.loading = false;
          this.doData.status = "Delivery in Progress";
          this.$bvToast.toast(e.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        })
      this.modalArrived = false;
    },

    updateReturn() {
      //update status to return
      //var data = { ...this.doData };
      var previousStatus= this.doData.status;
      var goBackPath = (previousStatus=="Delivery in Progress")?"Delivery in Progress":"Scanning Completed"
      //data.status = "Returned";
      this.loading = true;
      this.testScanDO({ idDelivery: this.doData._id, deliveryData: {status:"Returned"} })
        .then(() => {
          this.modalReturn = false;
          this.doData.status = "Returned";

          this.$bvToast.toast(
            "Delivery item(s) has been returned by the customer. Please bring back the item(s) to the warehouse and process the Return DO",
            {
              title: "Success",
              variant: "success",
              solid: true,
            }
          );

          
        })
        .catch((e) => {
          console.log({ e });
          this.doData.status = goBackPath;
          this.$bvToast.toast(e.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
            this.loading = false;
            //this.$store.dispatch("delivery/getDeliveryOrder");
            
            
            this.goBackCall(goBackPath)
          });
      
    },

    //this is for self collect data type to show button needed
    showCustomer() {
      this.modalCollect = false;
      this.modalCustomer = true;
    },
    updateSelfCollect() {
      if (this.customerCollect.idNumber === "") {
        this.$bvToast.toast("ID Number Empty", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      if (this.customerCollect.name === "") {
        this.$bvToast.toast("Name Empty", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      //update status to completed
      //var data = { ...this.doData };
      //data.status = "Collected";
      //data.customerCollect = this.customerCollect;
      this.loading=true;
      this.testScanDO({ idDelivery: this.doData._id, 
        deliveryData: {status:"Collected",customerCollect:this.customerCollect} })
        .then(() => {
          //update item warranty status at inventory
          this.modalCustomer = false;
          this.doData.status = "Collected";

          this.$bvToast.toast("The items has been collected successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((e) => {
          console.error(e);
          if (e.response.status === 424) {
            this.$bvToast.toast(
              "Fail to send notification to SAP. Please inform administrator",
              {
                title: "Failed",
                variant: "danger",
                solid: true,
              }
            );
          } else {
            
            this.$bvToast.toast(e.response.data.message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
            return;
          }
          this.modalCustomer = false;
        })
        .finally(() => {
            this.loading = false;
            //this.$store.dispatch("delivery/getDeliveryOrder");
            this.goBackCall('Collected')
          });
    },
    //END this is for self collect data type to show button needed

    //this is for return delivery process
    updateApproved() {
      //update status to approved
      let sapDo = this.doData.sapDo ;
      if((this.doData.sapInvoice==""||this.doData.sapInvoice==null||!this.doData.sapInvoice) && (this.doData.soType === "" || this.doData.soType==null||!this.doData.soType)){
        this.$bvToast.toast("Invoice number is not available. Please ensure Invoice number is generated before approving a return order", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        return;
      }
      this.loading = true;
      this.testScanDO({
        idDelivery: this.doData._id,
        deliveryData: { status: "Approved", sapDo: sapDo },
      })
        .then(() => {
          this.doData.status = "Approved";

          this.$bvToast.toast("Approve Succesfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.modalApprove = false;
          this.goBackCall('Approved')
        })
        .catch((e) => {
          // console.log(e)
          this.modalApprove = false
          this.doData.status = "Waiting Approval";
          this.$bvToast.toast(e.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.loading = false;
          //this.$store.dispatch("delivery/getDeliveryOrder");
        });
      
    },
    updateAcknowledgment() {
      //update status to acknowledge
      let sapDo = this.doData.sapDo;
      this.loading = true;
      this.testScanDO({
        idDelivery: this.doData._id,
        deliveryData: { status: "Waiting Approval", sapDo: sapDo },
      })
        .then(() => {
          this.doData.status = "Waiting Approval";

          this.$bvToast.toast("Acknowledgment Succesfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.modalAcknowledgment = false;
          this.goBackCall('Waiting Approval')
        })
        .catch((e) => {
          // console.log(e)
          this.doData.status = "Waiting Acknowledgment";
          this.$bvToast.toast(e.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.loading = false;
          //this.$store.dispatch("delivery/getDeliveryOrder");
        });
    },
    dissaporoveAcknowledgment() {
      this.loading = true
      let status;

      if (this.doData.manualReturnFlag == undefined || this.doData.manualReturnFlag == 0) {
        status = "Fulfilled"; // karena dia awalnya adalah dari delivery in progress dijadikan fulfilled
      } else {
        // Mengecek apakah setidaknya satu detail memiliki returnBarcode.length > 0 dan totalBarcodeLength === qty
        const hasValidReturn = this.doData.DO_details.some(detail => {
          const scannedBarcodeLength = detail.scannedBarcode.length;
          const returnBarcodeLength = detail.returnBarcode.length;
          const totalBarcodeLength = scannedBarcodeLength + returnBarcodeLength;
          return returnBarcodeLength > 0 && totalBarcodeLength === detail.qty;
        });

        if (hasValidReturn) {
          status = "Return Completed";
        } else {
          if(this.doData.priority == "03" || this.doData.priority == "05"){
            status = "Collected"
          }else {
            status = "Fulfilled"
          }
        }
      }

      axios.put(`sales-and-purchase/drcc/delivery/disapprovedo/${this.doData._id}`, 
      { status : status})
      .then(async (response) => {
        this.loading = false
        this.$bvToast.toast("Successfully Disapprove Return Acknowledgment", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          await sleep(1000)
          this.$router.push({
            path:this.backPath,
            query: { status: this.goBackStatus }
          });
      }).catch((e) => {
        this.loading = false
        console.log(e)
        this.$bvToast.toast('Failed to Disapprove Return Acknowledgment', {
          title: "Success",
          variant: "danger",
          solid: true,
        });
      })
    },

    dissaporoveApproval() {
      this.loading = true
      let status;

      if (this.doData.manualReturnFlag == undefined || this.doData.manualReturnFlag == 0) {
        status = "Fulfilled"; // karena dia awalnya adalah dari delivery in progress dijadikan fulfilled
      } else {
        // Mengecek apakah setidaknya satu detail memiliki returnBarcode.length > 0 dan totalBarcodeLength === qty
        const hasValidReturn = this.doData.DO_details.some(detail => {
          const scannedBarcodeLength = detail.scannedBarcode.length;
          const returnBarcodeLength = detail.returnBarcode.length;
          const totalBarcodeLength = scannedBarcodeLength + returnBarcodeLength;
          return returnBarcodeLength > 0 && totalBarcodeLength === detail.qty;
        });

        if (hasValidReturn) {
          status = "Return Completed";
        } else {
          if(this.doData.priority == "03" || this.doData.priority == "05"){
            status = "Collected"
          }else {
            status = "Fulfilled"
          }
        }
      }

      axios.put(`sales-and-purchase/drcc/delivery/disapprovedoapproval/${this.doData._id}`, 
      { status : status})
      .then(async (response) => {
        this.loading = false
        this.$bvToast.toast("Successfully Disapprove Return Approval", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          await sleep(1000)
          this.$router.push({
            path:this.backPath,
            query: { status: this.goBackStatus }
          });
      }).catch((e) => {
        this.loading = false
        console.log(e)
        this.$bvToast.toast('Failed to Disapprove Return Approval', {
          title: "Success",
          variant: "danger",
          solid: true,
        });
      })
    },

    updateRedelivery() {
      //update status to acknowledge
      let sapDo = this.doData.sapDo;
      this.loading = true;
      this.testScanDO({
        idDelivery: this.doData._id,
        deliveryData: { status: "Re-Delivery", sapDo: sapDo },
      })
        .then(() => {
          this.doData.status = "Re-Delivery";

          this.$bvToast.toast("Change DO to Re-Delivery Succesfull", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.modalRedelivery = false;
          this.goBackCall('Delivery in Progress')
        })
        .catch((e) => {
          // console.log(e)
          this.doData.status = "Delivery in Progress";
          this.$bvToast.toast(e.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.loading = false;
          //this.$store.dispatch("delivery/getDeliveryOrder");
        });
    },
    acceptRedelivery() {
      //update status to acknowledge
      let sapDo = this.doData.sapDo;
      this.loading = true;
      this.testScanDO({
        idDelivery: this.doData._id,
        deliveryData: { status: "Scanning Completed", sapDo: sapDo },
      })
        .then(() => {
          this.doData.status = "Scanning Completed";

          this.$bvToast.toast("Redelivery Accepted Succesfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.modalRedelivery = false;
          this.goBackCall('Re-delivery')
        })
        .catch((e) => {
          console.log(e)
          this.doData.status = "Re-delivery";
          this.$bvToast.toast('Re-delivery Failed, ', {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.loading = false;
          //this.$store.dispatch("delivery/getDeliveryOrder");
        });
    },
    goBackCall(query){
      if(this.backPath!==""){
        this.$router.push({ path: this.backPath, query: { status: query,sapDo:this.doData.sapDo } });
      }else{
        this.$router.push(`/`);
        this.$bvToast.toast('Shipping Point Not Found', {
          title: "Warning",
          variant: "danger",
          solid: true,
          });
      }
    },
    
    //END this is for return delivery process
    selectItem(row) {
      this.selectScan = row.item;
      this.pagetwo = false;
      this.pagethree = true;
      //console.log('this.selectScan', this.selectScan)
    },
    selectItemByIndex(index) {
      this.selectIndex = index;
      this.selectScan = this.doData.DO_details[index];
      //console.log("select scan index " + index + " ", this.selectScan);
      this.pagetwo = false;
      this.pagethree = true;
    },
    getSummary(material) {
      let itemSummary = this.summary[material];
      if(itemSummary==undefined){
        console.info('item summary is undefined ',material);
        itemSummary={scanQty:0,returnedQty:0}
      }
      return itemSummary;
    },
    nextPage() {
      this.pageone = false;
      this.pagetwo = true;
    },
    backPage() {
      this.pageone = true;
      this.pagetwo = false;
    },
    backPageTwo() {
      this.pagetwo = true;
      this.pagethree = false;
    },
    clearBarcode() {
      this.barcode = null;
    },
    getItem(data) {
      //console.log("data", this.doData);
      var searchItem = this.itemCatalog.find((x) => {
        return x._id == data;
      });
      var result;
      if (searchItem === undefined) {
        result = {
          itemModel: " ",
        };
      } else {
        result = searchItem;
      }
      //console.log("getItem(data).", result);
      return result;
    },
  },
  computed: {
    summary() {
      if (
        this.doData.status == "Waiting Acknowledgment" ||
        this.doData.status == "Waiting Approval"
      ) {
        var temp = {};
        for (var i = 0; i < this.doData.DO_details.length; i++) {
          let DO_temp = this.doData.DO_details[i];
          let qtyReturn = (DO_temp.qtyUpdate!=undefined)?DO_temp.qtyUpdate:DO_temp.qty;
          console.info('DO_emp ',DO_temp,qtyReturn);
          // temp[DO_temp.material] = {scanQty:DO_temp.qty-DO_temp.scannedBarcode.length}
          var returnedQty = qtyReturn-DO_temp.scannedBarcode.length;
          temp[DO_temp.material] = {
            
            //returnedQty, number of barcode already returned 
            returnedQty:returnedQty,
            
            //scanQty, number of barcode needed to be unscan
            scanQty: DO_temp.returnBarcode.length-returnedQty,
            
            //overal return total
            totalReturn: DO_temp.returnBarcode.length};
          }
          console.info('temp ',temp)
        return temp;
      } else {
        return this.scanItemTables.reduce((prev, next) => {
          if (prev[next.material] === undefined) {
            prev[next.material] = { scanQty: 0, emptyQty: 0 };
          }

          if (next.scannedBarcode === "") {
            prev[next.material].emptyQty += 1;
          } else {
            prev[next.material].scanQty += 1;
          }
          return prev;
        }, {});
      }
    },
    listItemScan() {
      var result = this.scanItemTables
        .filter((x) => {
          return x.material === this.selectScan.material;
        })
        .filter((el) => {
          return el.returnBarcode !== "";
        });

      console.info("listItemScan ",result)
      return result;
    },
    permission() {
      var menuName, menuPermission;

      if (this.goBack === "do-daikin") {
        menuName = "Delivery Orders Daikin";
        menuPermission = "delivery_order_menu";
      }
      if (this.goBack === "do-sumitomo") {
        menuName = "Delivery Orders Sumitomo";
        menuPermission = "delivery_order_menu";
      }
      if (this.goBack === "sc-daikin") {
        menuName = "Self Collect Daikin";
        menuPermission = "self_collect_menu";
      }
      if (this.goBack === "sc-sumitomo") {
        menuName = "Self Collect Sumitomo";
        menuPermission = "self_collect_menu";
      }
      if (this.goBack === "return-daikin") {
        menuName = "Return Delivery Daikin";
        menuPermission = "return_delivery_menu";
      }
      if (this.goBack === "return-sumitomo") {
        menuName = "Return Delivery Sumitomo";
        menuPermission = "return_delivery_menu";
      }
      if (this.goBack === "manual-return-daikin") {
        menuName = "Manual Return Delivery Daikin";
        menuPermission = "return_delivery_menu";
      }
      if (this.goBack === "manual-return-sumitomo") {
        menuName = "Manual Return Delivery Sumitomo";
        menuPermission = "return_delivery_menu";
      }
      if (this.goBack === "shipping-daikin") {
        menuName = "Shipping Daikin";
        menuPermission = "shipping_menu";
      }
      if (this.goBack === "shipping-sumitomo") {
        menuName = "Shipping Sumitomo";
        menuPermission = "shipping_menu";
      }
      if(this.goBack === 'redelivery-sumitomo'){
        menuName = "Redelivery Sumitomo";
        menuPermission = "redelivery_menu";
      }
      if(this.goBack === 'redelivery-daikin'){
        menuName = "Redelivery Daikin";
        menuPermission = "redelivery_menu";
      }
      let result = userAccess(menuName, menuPermission);
      //console.log("permission "+menuName+" "+menuPermission+" ", result)
      return result;
    },
    checkShipping() {
      if (
        this.permission.return &&
        this.goBack === "shipping" &&
        this.doData.status === "Delivery in Progress"
      ) {
        show = true;
      }

      return show;
    },
    checkCollected() {
      //this is for show button self collect completed

      var show = false;
      //console.log("this.permission.collected", this.permission.collected);
      //console.log("this.permission.return", this.permission.return);
      if (
        this.permission.collected &&
        this.goBack === "sc-daikin" &&
        this.doData.status === "Scanning Completed"
      ) {
        show = true;
      }
      if (
        this.permission.collected &&
        this.goBack === "sc-sumitomo" &&
        this.doData.status === "Scanning Completed"
      ) {
        show = true;
      }
      return show;
    },
    //this is for show button delivery returned
    checkReturn() {
      var show = false;
      if (
        this.permission.return &&
        this.goBack === "sc-daikin" &&
        this.doData.status === "Scanning Completed"
      ) {
        show = true;
      }
      if (
        this.permission.return &&
        this.goBack === "sc-sumitomo" &&
        this.doData.status === "Scanning Completed"
      ) {
        show = true;
      }
      if (
        this.permission.return &&
        this.goBack === "shipping" &&
        this.doData.status === "Delivery in Progress"
      ) {
        show = true;
      }

      return show;
    },

    checkRedelivery() {
      var show = false;
      /**

      temporary, redelivery don't need permission
      **/
      if (
        //this.permission.redelivery &&
        this.goBack === "shipping" &&
        this.doData.status === "Delivery in Progress"
      ) {
        show = true;
      }

      return show;
    },
    //this is for show button return delivery acknowledge, *cek : tar ditambah permission
    checkAcknowledge() {
      var show = false;
      if (
        this.goBack === "return-daikin" &&
        this.doData.status === "Waiting Acknowledgment"
      ) {
        show = true;
      }
      if (
        this.goBack === "return-sumitomo" &&
        this.doData.status === "Waiting Acknowledgment"
      ) {
        show = true;
      }
      if (
        this.goBack === "manual-return-daikin" &&
        this.doData.status === "Waiting Acknowledgment"
      ) {
        show = true;
      }
      if (
        this.goBack === "manual-return-sumitomo" &&
        this.doData.status === "Waiting Acknowledgment"
      ) {
        show = true;
      }

      return show;
    },
    //this is for show button return delivery approval, *cek : tar ditambah permission
    checkApproval() {
      var show = false;
      if (
        this.goBack === "return-daikin" &&
        this.doData.status === "Waiting Approval"
      ) {
        show = true;
      }
      if (
        this.goBack === "return-sumitomo" &&
        this.doData.status === "Waiting Approval"
      ) {
        show = true;
      }
      if (
        this.goBack === "manual-return-daikin" &&
        this.doData.status === "Waiting Approval"
      ) {
        show = true;
      }
      if (
        this.goBack === "manual-return-sumitomo" &&
        this.doData.status === "Waiting Approval"
      ) {
        show = true;
      }

      return show;
    },
    //this for looping table
    filteredItems() {
      const result = {};
      
      this.doData.DO_details.forEach(item => {
        item.returnBarcode.forEach(barcode => {
          const flag = barcode.flag;
          if (!result[flag]) {
            result[flag] = [];
          }
          const mergedItem = Object.assign({}, item, barcode);
          result[flag].push(mergedItem);
        });
      });
      // Memastikan ada kelompok untuk undefined flag
      if (!result[undefined]) {
        result[undefined] = this.doData.DO_details.filter(item =>
          item.returnBarcode.some(barcode => barcode.flag === undefined) && item.returnBarcode.length !== 0
        ).map(item => {
          return item.returnBarcode.map(barcode => Object.assign({}, item, barcode));
        }).flat();
      }

      this.currentFlag = "Normal Return"
      console.log("result", result);
      return result;
    },
    selectOptions() {
      return Object.keys(this.filteredItems).map(flag => (
        {
        value: flag,
        text: this.flagLabel(flag)
      }));
    }
  },
};
</script>

<style>
/* For mobile phones: */
@media (max-width: 761px) {
  .adjust-on-mobile {
    margin-bottom: 10px !important;
  }
  .paging-center-mobile {
    justify-content: center !important;
  }
}

.button-group-responsive {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.button-group-responsive > * {
  flex: 1;
  min-width: 100px;
  margin-bottom: 0.5rem;
}
</style>
